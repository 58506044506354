module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"James","start_url":"/","background_color":"#4d9aff","theme_color":"#4d9aff","display":"standalone","icon":"src/images/app-icon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"d492cefa4f0bead3ac4656e9077d31ea"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-7623829-17"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
